import { useContext, useState, useEffect } from 'react';
import { Button, Col, Form, message, Row } from 'antd';
import {
  BtnContribute,
  CardStyled,
  Container,
  FormItem,
  InputStyled,
  Subtitle
} from './styled';
import './tablestyles.css';
import { Context } from 'context/contex';
import $ from "jquery";

import Spinner from 'react-spinkit';
import './App.css';
import React from 'react';
import icon from 'assets/nexxicon.png';
import { isCommunityResourcable } from '@ethersproject/providers';

export default function Home() {
 
  //=====================================================================
 
  //PRESALE CONTRACT
  const {
    priceLoading,
    account,
    BLDSBalance
    
  } = useContext(Context);

  const [code, setCode] = useState('');
  const [notice, setNotice] = useState('');
  const [loading, setLoading] = useState(false);
 
//const add = account;



const getCode = () => {
  if(!account) return alert("Please connect a wallet");
  if(parseInt(BLDSBalance) < 1) return alert("You have no NFT from this collection.");
  $.ajax({
      type: "POST",
      url: "getcode.php",
      data: {address: account},
      success(data) {
          setCode(data);
      },
  });
};

const getNotice = () => {
  $.ajax({
      type: "POST",
      url: "getcode.php",
      data: {notext: 1},
      success(data2) {
          setNotice(data2);
      },
      error(err) {
        setNotice(err.responseText);
    }
  });
};

useEffect(() => {
  getNotice();
}, []);

 // console.log(notice)

  return (
    <Container>
    
      <br />
      <center>
        <h1 style={{color:'#FDCE00'}}>Claim your NWVTP Benefits</h1>
      
                <p>&nbsp;</p>  

      </center>
      <CardStyled>
        <Form layout="vertical" color="white">
        
          <Row justify="center">
          </Row>
          <FormItem label="Your NWVTP balance:">
            {priceLoading ? (
              <Row justify="center">
                <Spinner name="circle" color="#f2bd48" />
              </Row>
            ) : (
              <Row justify="space-between" align="middle">
                <InputStyled
                  readOnly
                  placeholder="0.00"
                  value={parseInt(BLDSBalance)}
                />
                <div style={{ width: '35%', display: 'inline' }}>
                  <img src={icon} alt='Nexxworks' width="auto" height="32" />
                  <span style={{ color: '#fff', marginLeft: '10px' }}>NWVTP</span>
                </div>
              </Row>
            )}
          </FormItem>
        
          <p></p>  
           {account && code != '' ? 
          <Row justify="center" style={{color:'#FDCE00'}}>
          <span style={{color:'#fff'}}>Your promo code is:</span> <span style={{fontSize:'22px'}}>{code}</span>
          <p>&nbsp;</p>  
          </Row>
         : '' }
                    {code != '' ? 

          <div>
            <div style={{color:'#fff'}}>{notice}</div>
            <p></p>  
            </div>
:''}
          <BtnContribute
            type="ghost"
            loading={loading}
            onClick={getCode}
            //disabled
          >
            Get promo code
          </BtnContribute>
        </Form>
          


      </CardStyled>
      <Subtitle>How it works?</Subtitle>
      <p style={{wordBreak: 'break-word'}}>
        Follow the steps below to get your benefit: <br />
        1. Connect to Metamask or Trustwallet. <br />
        2. Change network to Polygon Mainnet. <br />
        3. Make sure you have NWVTP NFT available in your wallet.. <br />
        5. Press GET PROMO CODE button. <br />
        <br />
        OFFICIAL NWVTP NFT ADDRESS :
        0x79db252F8754835b019087C58D382F9fe9392956
      </p>
   

    
    </Container>


  );
}