import { BreakLine, Container, Logo, Social, Wrapper } from "./styles";
import pawzlogo from '../../assets/nexxlogo.png';

export default function Footer() {
  return (
    <Wrapper>
      <BreakLine />
      <Container>
        <Logo
          alt='BLDS'
          src={pawzlogo}
        />
        <Social>
       
          <a href='https://www.facebook.com/nexxworks/' target='_blank' rel="noreferrer">
            <img 
              src='https://global-uploads.webflow.com/626801c53ac5ce0822217a8c/626801c53ac5cec7aa217a93_Facebook.svg'
              alt='twitter'
              width={50}
            />
          </a>
          <a href='https://twitter.com/nexxworks' target='_blank' rel="noreferrer">
            <img 
              src='https://global-uploads.webflow.com/626801c53ac5ce0822217a8c/626801c53ac5ce5565217c42_twitter-icon.svg'
              alt='twitter'
              width={50}
            />
          </a>
          <a href='https://www.instagram.com/nexxworks/' target='_blank' rel="noreferrer">
            <img 
              src='https://global-uploads.webflow.com/626801c53ac5ce0822217a8c/626801c53ac5ce06f4217a92_Instagram.svg'
              alt='telegram'
              width={50}
            />
          </a>
          <a href='https://www.linkedin.com/company/nexxworks/' target='_blank' rel="noreferrer">
            <img 
              src='https://global-uploads.webflow.com/626801c53ac5ce0822217a8c/626801c53ac5ce3387217a94_Linkedin.svg'
              alt='telegram'
              width={50}
            />
          </a>
    
        
        </Social>
      </Container>
    </Wrapper>
  )
}