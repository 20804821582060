import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { 
  Wrapper,
  Container,
  Logo
} from "./styles";
import logo from 'assets/nexxlogo.png';
import ConnectWallet from 'components/ConnectWallet';


export default function Header() {
  const location = useLocation();
  const [navActive, setNavActive] = useState(false);

  return (
    <Wrapper>
      <Container>
        <Link to="/">
          <Logo alt="NEXXWORKS" src={logo} />
        </Link>
        
        <ConnectWallet />
      
      </Container>
    </Wrapper>
  );
}